<template>
  <div class="col-12">
    <div class="p-fluid formgrid grid">
      <div v-for="imagen in imagenes" :key="imagen.id">
        <div class="field col-12 md:col-12">
          <div class="date">
            <strong>Fecha:</strong>
            {{ new Date(imagen.created_at).toLocaleString() }}
          </div>
          <Image
            :src="'data:image/png;base64, ' + imagen.image"
            width="200"
            height="200"
            alt="imagen"
            preview
          >
          </Image>

          <div class="p-inputgroup mb-1">
            <Textarea
              v-model="imagen.descripcion"
              :rows="1"
              autoResize
              :placeholder="'Descripción de la imagen'"
            />
            <Button
              icon="pi pi-save"
              v-tooltip.top="'Guardar Descripción'"
              @click="guardarDescripcion(imagen)"
              class="p-button-info"
            />
          </div>
          <Button
            label="Eliminar"
            icon="pi pi-trash"
            class="p-button-outlined p-button-danger p-button-sm"
            @click="eliminarImagen(imagen.id)"
            v-tooltip.top="'Quitar Imagen'"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="flex md:justify-content-end">
    <FileUpload
      mode="basic"
      name="demo[]"
      accept="image/jpeg,image/png,image/jpg,image/jpg"
      :customUpload="true"
      chooseLabel="NUEVA IMAGEN"
      @uploader="onUpload"
      :auto="true"
      class="p-button-info p-button-lg"
    />
  </div>
</template>

<script>
import ClienteService from "@/service/ClienteService";

export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    cliente_id: {
      type: Number,
      default: 0,
    },
  },
  clienteService: null,
  data() {
    return {
      mostrarModal: this.show,
      detalle: [],
      imagenes: [],
      loading: true,
    };
  },
  created() {
    this.clienteService = new ClienteService();
  },
  methods: {
    guardarDescripcion(imagen) {
      let datos = {
        id: imagen.id,
        cliente_id: imagen.cliente_id,
        descripcion: imagen.descripcion,
      };
      this.clienteService.updateImage(datos).then(() => {
        this.buscarImagenCliente();
        this.$toast.add({
          severity: "success",
          summary: "Actualizado",
          detail: "Descripción actualizada correctamente",
          life: 3000,
        });
      });
    },
    eliminarImagen(imagen_id) {
      this.clienteService.deleteImage(imagen_id).then(() => {
        this.buscarImagenCliente();
        this.$toast.add({
          severity: "success",
          summary: "Actualizado",
          detail: "Imagen eliminada correctamente",
          life: 3000,
        });
      });
    },
    cerrarModal() {
      this.mostrarModal = false;
      this.$emit("closeModal");
      this.$emit("actualizarListado");
    },
    buscarImagenCliente() {
      this.clienteService.getClienteImages(this.cliente_id).then((data) => {
        this.detalle = data.cliente;
        this.imagenes = data.images;
        this.loading = false;
      });
    },
    onUpload(event) {
      let archivo = event.files[0];
      let formData = new FormData();
      formData.append("imagen", archivo);
      formData.append("cliente_id", this.cliente_id);

      this.clienteService
        .uploadImage(formData)
        .then(() => {
          this.buscarImagenCliente();
          this.$toast.add({
            severity: "success",
            summary: "Actualizado",
            detail: "Imagen cargada correctamente",
            life: 3000,
          });
          this.$emit("actualizarListado");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    cliente_id(val) {
      if (val > 0) {
        this.buscarImagenCliente();
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-imputgroup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #ccc;
  margin: 10px;

  img {
    border-radius: 10px;
  }

  button {
    margin-top: 10px;
  }
}
.date {
  font-size: 1.3em;
  margin-bottom: 0em;
}
</style>
