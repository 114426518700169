<template>
  <div class="grid">
    <div class="col-12 md:col-1 color-linea">
      <span style="font-size: 12px; font-weight: bold">HISTORICO</span>
      <div
        v-for="(item, index) in fechas"
        :key="index"
        @click="fechaSeleccionada(item.value)"
        :class="
          item.value == data_datos_eco_obstetrico.fecha_reg ? 'bg-gray-200' : ''
        "
        style="border: 1px solid #ccc; border-top: none; cursor: pointer"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="col-12 md:col-11">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span"> Gestaciones: </span>
            <span class="p-float-label">
              <Textarea
                v-model="data_datos_eco_obstetrico.gestaciones"
                v-tooltip.top="'Gestaciones'"
                rows="1"
                autoResize
              />
              <label for="inputtext"></label>
            </span>
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span"> Partos: </span>
            <span class="p-float-label">
              <Textarea
                v-model="data_datos_eco_obstetrico.partos"
                v-tooltip.top="'Partos'"
                rows="1"
                autoResize
              />
              <label for="inputtext"></label>
            </span>
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span"> Abortos: </span>
            <span class="p-float-label">
              <Textarea
                v-model="data_datos_eco_obstetrico.abortos"
                v-tooltip.top="'Abortos'"
                rows="1"
                autoResize
              />
              <label for="inputtext"></label>
            </span>
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span"> Cesáreas: </span>
            <span class="p-float-label">
              <Textarea
                v-model="data_datos_eco_obstetrico.cesareas"
                v-tooltip.top="'Cesareas'"
                rows="1"
                autoResize
              />
              <label for="inputtext"></label>
            </span>
          </div>
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span"> FUM: </span>
            <span class="p-float-label">
              <Textarea
                v-model="data_datos_eco_obstetrico.fum"
                v-tooltip.top="'FUM'"
                rows="1"
                autoResize
              />
              <label for="inputtext"></label>
            </span>
          </div>
        </div>
        <div class="field col-12 md:col-9">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span"> F.P.P.: </span>
            <span class="p-float-label">
              <Textarea
                v-model="data_datos_eco_obstetrico.fpp"
                v-tooltip.top="'F.P.P.'"
                rows="1"
                autoResize
              />
              <label for="inputtext"></label>
            </span>
          </div>
        </div>
      </div>
      <p class="titulo-izquierdo">
        <strong>A LOS DIFERENTES CORTES Y BARRIDOS, SE VISUALIZA:</strong>
      </p>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-1">
          <p class="titulo-izquierdo"><strong>VEJIGA:</strong></p>
        </div>
        <div class="field col-12 md:col-5">
          <div class="p-inputgroup flex align-items-center">
            <label for="llena">Llena</label>
            <RadioButton
              inputId="llena"
              value="Llena"
              v-model="data_datos_eco_obstetrico.vejiga"
              v-tooltip.top="'Llena'"
              class="mr-2"
            />
            <label for="semillena">Semillena</label>
            <RadioButton
              inputId="semillena"
              value="Semillena"
              v-model="data_datos_eco_obstetrico.vejiga"
              v-tooltip.top="'Semillena'"
              class="mr-2"
            />
            <label for="vacia">Vacía</label>
            <RadioButton
              inputId="vacia"
              value="Vacia"
              v-model="data_datos_eco_obstetrico.vejiga"
              v-tooltip.top="'Vacia'"
              class="mr-2"
            />
          </div>
        </div>
        <div class="field col-12 md:col-6">
          <div class="p-inputgroup flex align-items-center">
            <span class="mr-2">
              <strong class="mt-2">Aspecto Normal: </strong>
            </span>
            <label for="aspecto_normal">SI</label>
            <RadioButton
              inputId="aspecto_normal"
              value="Si"
              v-model="data_datos_eco_obstetrico.vejiga_aspecto_normal"
              v-tooltip.top="'SI'"
              class="mr-2"
            />
            <label for="aspecto_normal_no">NO</label>
            <RadioButton
              inputId="aspecto_normal_no"
              value="No"
              v-model="data_datos_eco_obstetrico.vejiga_aspecto_normal"
              v-tooltip.top="'NO'"
              class="mr-2"
            />
          </div>
        </div>
      </div>

      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-1">
          <p class="titulo-izquierdo"><strong>ÚTERO:</strong></p>
        </div>
        <div class="field col-12 md:col-5">
          <div class="p-inputgroup flex align-items-center">
            <label for="anteversion">Anteversión</label>
            <RadioButton
              inputId="anteversion"
              value="Anteversion"
              v-model="data_datos_eco_obstetrico.utero_an_re_me"
              v-tooltip.top="'Anteversión'"
              class="mr-2"
            />
            <label for="retroversion">Retroversión</label>
            <RadioButton
              inputId="retroversion"
              value="Retroversion"
              v-model="data_datos_eco_obstetrico.utero_an_re_me"
              v-tooltip.top="'Retroversión'"
              class="mr-2"
            />
            <label for="medioversion">Medioversión</label>
            <RadioButton
              inputId="medioversion"
              value="Medioversion"
              v-model="data_datos_eco_obstetrico.utero_an_re_me"
              v-tooltip.top="'Medioversión'"
              class="mr-2"
            />
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup flex align-items-center">
            <span class="mr-2"><strong>Miometrio:</strong></span>
            <span class="mr-2"> Homogéneo </span>
            <label for="si">SI</label>
            <RadioButton
              inputId="si"
              value="Si"
              v-model="data_datos_eco_obstetrico.utero_mio_homog"
              v-tooltip.top="'SI'"
              class="mr-2"
            />
            <label for="no">NO</label>
            <RadioButton
              inputId="no"
              value="No"
              v-model="data_datos_eco_obstetrico.utero_mio_homog"
              v-tooltip.top="'NO'"
              class="mr-2"
            />
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup flex align-items-center">
            <span class="mr-2">Tumores</span>
            <label for="si">SI</label>
            <RadioButton
              inputId="si"
              value="Si"
              v-model="data_datos_eco_obstetrico.utero_tumores"
              v-tooltip.top="'SI'"
              class="mr-2"
            />
            <label for="no">NO</label>
            <RadioButton
              inputId="no"
              value="No"
              v-model="data_datos_eco_obstetrico.utero_tumores"
              v-tooltip.top="'NO'"
              class="mr-2"
            />
          </div>
        </div>
        <div class="field col-12 md:col-1"></div>
        <div class="field col-12 md:col-5">
          <div class="p-inputgroup flex align-items-center">
            <span class="mr-2"><strong>Saco Gestacional:</strong></span>
            <span class="mr-2"> Visible </span>
            <label for="si">SI</label>
            <RadioButton
              inputId="si"
              value="Si"
              v-model="data_datos_eco_obstetrico.utero_saco_ge_vi"
              v-tooltip.top="'SI'"
              class="mr-2"
            />
            <label for="no">NO</label>
            <RadioButton
              inputId="no"
              value="No"
              v-model="data_datos_eco_obstetrico.utero_saco_ge_vi"
              v-tooltip.top="'NO'"
              class="mr-2"
            />
          </div>
        </div>
        <div class="field col-12 md:col-6">
          <div class="p-inputgroup flex align-items-center">
            <span class="mr-2"><strong>Diámetro S.G.:</strong></span>
            <Textarea
              v-model="data_datos_eco_obstetrico.utero_diam_sg"
              v-tooltip.top="'Diámetro S.G.'"
              rows="1"
              autoResize
              class="style-textarea"
            />
          </div>
        </div>
        <div class="field col-12 md:col-1"></div>
        <div class="field col-12 md:col-5">
          <div class="p-inputgroup flex align-items-center">
            <span class="mr-2"><strong>Paredes del S.G.</strong> </span>
            <label for="regulares">Regulares</label>
            <RadioButton
              inputId="regulares"
              value="Regulares"
              v-model="data_datos_eco_obstetrico.utero_paredes_sg"
              v-tooltip.top="'Regulares'"
              class="mr-2"
            />
            <label for="irregulares">Irregulares</label>
            <RadioButton
              inputId="irregulares"
              value="Irregulares"
              v-model="data_datos_eco_obstetrico.utero_paredes_sg"
              v-tooltip.top="'Irregulares'"
              class="mr-2"
            />
            <label for="gruesas">Gruesas</label>
            <RadioButton
              inputId="gruesas"
              value="Gruesas"
              v-model="data_datos_eco_obstetrico.utero_paredes_sg"
              v-tooltip.top="'Gruesas'"
              class="mr-2"
            />
          </div>
        </div>
        <div class="field col-12 md:col-6">
          <div class="p-inputgroup flex align-items-center">
            <span class="mr-2"><strong>Botón Emb.: </strong></span>
            <span class="mr-2">Visible</span>

            <label for="si">SI</label>
            <RadioButton
              inputId="si"
              value="Si"
              v-model="data_datos_eco_obstetrico.utero_bot_emb_vi"
              v-tooltip.top="'SI'"
              class="mr-2"
            />
            <label for="no">NO</label>
            <RadioButton
              inputId="no"
              value="No"
              v-model="data_datos_eco_obstetrico.utero_bot_emb_vi"
              v-tooltip.top="'NO'"
              class="mr-2"
            />
          </div>
        </div>
      </div>

      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-2">
          <p class="titulo-izquierdo"><strong>BIOMETRÍA FETAL:</strong></p>
        </div>
        <div class="field col-12 md:col-5">
          <div class="p-inputgroup flex align-items-center">
            <span class="mr-2">
              <strong>Líquido Ovular Cantidad: </strong>
            </span>
            <label for="normal">Normal</label>
            <RadioButton
              inputId="normal"
              value="Normal"
              v-model="data_datos_eco_obstetrico.b_fetal_normal"
              v-tooltip.top="'Normal'"
            />
            <Textarea
              v-model="data_datos_eco_obstetrico.b_fetal_normal_descripcion"
              v-tooltip.top="'Descripción'"
              rows="1"
              autoResize
              class="style-textarea"
            />
          </div>
        </div>
        <div class="field col-12 md:col-5">
          <div class="p-inputgroup flex align-items-center">
            <span>
              <strong>Embrión midiendo de L.C.N.: </strong>
            </span>
            <Textarea
              v-model="data_datos_eco_obstetrico.b_fetal_embrion_lcn"
              v-tooltip.top="'Embrión midiendo de L.C.N.'"
              rows="1"
              autoResize
              class="style-textarea"
            />
          </div>
        </div>
      </div>

      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-1">
          <p class="titulo-izquierdo"><strong>FETO:</strong></p>
        </div>
        <div class="field col-12 md:col-5">
          <div class="p-inputgroup flex align-items-center">
            <label for="unico">Único</label>
            <RadioButton
              inputId="unico"
              value="Unico"
              v-model="data_datos_eco_obstetrico.feto_unico"
              v-tooltip.top="'Único'"
              class="mr-2"
            />
            <label for="multiple">Múltiple</label>
            <span class="p-float-label">
              <Textarea
                v-model="data_datos_eco_obstetrico.feto_multiple"
                v-tooltip.top="'Múltiple'"
                rows="1"
                autoResize
              />
            </span>
          </div>
        </div>
        <div class="field col-12 md:col-6">
          <div class="p-inputgroup flex align-items-center">
            <span class="mr-2">
              <strong>L.C.F.: </strong>
            </span>
            <label for="presente">Presente</label>
            <RadioButton
              inputId="feto_lcf"
              value="Presente"
              v-model="data_datos_eco_obstetrico.feto_lcf"
              v-tooltip.top="'Presente'"
              class="mr-2"
            />
            <label for="ausente">Ausente</label>
            <RadioButton
              inputId="feto_lcf"
              value="Ausente"
              v-model="data_datos_eco_obstetrico.feto_lcf"
              v-tooltip.top="'Ausente'"
              class="mr-2"
            />
            <span class="p-float-label">
              <Textarea
                v-model="data_datos_eco_obstetrico.feto_lcf_descripcion"
                v-tooltip.top="'Feto L.C.F. Descripción'"
                rows="1"
                autoResize
              />
            </span>
          </div>
        </div>
        <div class="field col-12 md:col-1"></div>
        <div class="field col-12 md:col-5">
          <div class="p-inputgroup flex align-items-center">
            <span class="mr-2">
              <strong>Mov. Fetal:</strong>
            </span>
            <label for="presente">Presente</label>
            <RadioButton
              inputId="feto_lcf"
              value="Presente"
              v-model="data_datos_eco_obstetrico.feto_mov_fetal"
              v-tooltip.top="'Presente'"
              class="mr-2"
            />
            <label for="ausente">Ausente</label>
            <RadioButton
              inputId="feto_lcf"
              value="Ausente"
              v-model="data_datos_eco_obstetrico.feto_mov_fetal"
              v-tooltip.top="'Ausente'"
              class="mr-2"
            />
            <span class="mr-2">
              <strong>L.C.F.: </strong>
            </span>
            <Textarea
              v-model="data_datos_eco_obstetrico.feto_mov_fetal_lcf"
              v-tooltip.top="'L.C.F.'"
              rows="1"
              autoResize
              class="style-textarea"
            />
          </div>
        </div>
        <div class="field col-12 md:col-6">
          <div class="p-inputgroup flex align-items-center">
            <span class="mr-2">
              <strong>Situación:</strong>
            </span>
            <label for="long">Long.</label>
            <RadioButton
              inputId="feto_situacion"
              value="Long."
              v-model="data_datos_eco_obstetrico.feto_situacion"
              v-tooltip.top="'Long.'"
              class="mr-2"
            />
            <label for="oblicuo">Oblicuo</label>
            <RadioButton
              inputId="feto_situacion"
              value="Oblicuo"
              v-model="data_datos_eco_obstetrico.feto_situacion"
              v-tooltip.top="'Oblicuo'"
              class="mr-2"
            />
            <label for="transverso">Transverso</label>
            <RadioButton
              inputId="feto_situacion"
              value="Transverso"
              v-model="data_datos_eco_obstetrico.feto_situacion"
              v-tooltip.top="'Transverso'"
              class="mr-2"
            />
          </div>
        </div>
        <div class="field col-12 md:col-1"></div>
        <div class="field col-12 md:col-5">
          <div class="p-inputgroup flex align-items-center">
            <span class="mr-2">
              <strong>Presentación:</strong>
            </span>
            <label for="cefalica">Cefálica</label>
            <RadioButton
              value="Cefalica"
              v-model="data_datos_eco_obstetrico.feto_presentacion"
              v-tooltip.top="'Presentación Cefálica'"
              class="mr-2"
            />
            <label for="pelvica">Pélvica</label>
            <RadioButton
              value="Pelvica"
              v-model="data_datos_eco_obstetrico.feto_presentacion"
              v-tooltip.top="'Presentación Pélvica'"
              class="mr-2"
            />
            <label for="hombro">Hombro</label>
            <RadioButton
              value="Transverso"
              v-model="data_datos_eco_obstetrico.feto_presentacion"
              v-tooltip.top="'Presentación Hombro'"
              class="mr-2"
            />
            <label for="indiferente">Indiferente</label>
            <RadioButton
              value="Indiferente"
              v-model="data_datos_eco_obstetrico.feto_presentacion"
              v-tooltip.top="'Presentación Indiferente'"
              class="mr-2"
            />
          </div>
        </div>
        <div class="field col-12 md:col-6">
          <div class="p-inputgroup flex align-items-center">
            <span class="mr-2">
              <strong>Posición:</strong>
            </span>
            <Textarea
              v-model="data_datos_eco_obstetrico.feto_posicion"
              v-tooltip.top="'Posición'"
              rows="1"
              autoResize
              class="style-textarea"
            />
          </div>
        </div>
        <div class="field col-12 md:col-1"></div>
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup flex align-items-center">
            <span class="mr-2">
              <strong>D.B.P.</strong>
            </span>
            <Textarea
              v-model="data_datos_eco_obstetrico.feto_posicion_dbp"
              v-tooltip.top="'D.B.P.'"
              rows="1"
              autoResize
              class="style-textarea"
            />
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup flex align-items-center">
            <span class="mr-2">
              <strong>C.C.</strong>
            </span>
            <Textarea
              v-model="data_datos_eco_obstetrico.feto_posicion_cc"
              v-tooltip.top="'C.C.'"
              rows="1"
              autoResize
              class="style-textarea"
            />
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup flex align-items-center">
            <span class="mr-2">
              <strong>C.A.</strong>
            </span>
            <Textarea
              v-model="data_datos_eco_obstetrico.feto_posicion_ca"
              v-tooltip.top="'C.A.'"
              rows="1"
              autoResize
              class="style-textarea"
            />
          </div>
        </div>
        <div class="field col-12 md:col-2">
          <div class="p-inputgroup flex align-items-center">
            <span class="mr-2">
              <strong>L.F.</strong>
            </span>
            <Textarea
              v-model="data_datos_eco_obstetrico.feto_posicion_lf"
              v-tooltip.top="'L.F.'"
              rows="1"
              autoResize
              class="style-textarea"
            />
          </div>
        </div>
      </div>

      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-2">
          <p class="titulo-izquierdo"><strong>LÍQUIDO AMNIÓTICO:</strong></p>
        </div>
        <div class="field col-12 md:col-2">
          <div class="p-inputgroup flex align-items-center">
            <label for="normal">Normal</label>
            <RadioButton
              value="Normal"
              v-model="data_datos_eco_obstetrico.liq_amniotico"
              v-tooltip.top="'Normal'"
              class="mr-2"
            />
            <label for="escaso">Escaso</label>
            <RadioButton
              value="Escaso"
              v-model="data_datos_eco_obstetrico.liq_amniotico"
              v-tooltip.top="'Escaso'"
              class="mr-2"
            />
          </div>
        </div>
        <div class="field col-12 md:col-8">
          <div class="p-inputgroup flex align-items-center">
            <label for="dis_aumentado">Discretamente aumentado</label>
            <RadioButton
              value="Dis_aumentado"
              v-model="data_datos_eco_obstetrico.liq_amniotico"
              v-tooltip.top="'Discretamente aumentado'"
              class="mr-2"
            />
            <label for="aumentado">Aumentado</label>
            <RadioButton
              value="Aumentado"
              v-model="data_datos_eco_obstetrico.liq_amniotico"
              v-tooltip.top="'Aumentado'"
              class="mr-2"
            />
          </div>
        </div>
      </div>

      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-2">
          <p class="titulo-izquierdo"><strong>PLACENTA: Inserción</strong></p>
        </div>
        <div class="field col-12 md:col-2">
          <Textarea
            v-model="data_datos_eco_obstetrico.placenta_insercion"
            v-tooltip.top="'Inserción'"
            rows="1"
            autoResize
            class="style-textarea"
          />
        </div>
        <div class="field col-12 md:col-5">
          <div class="p-inputgroup flex align-items-center">
            <span class="titulo-izquierdo mr-2">
              <strong>Grado de madurez placentaria: </strong>
            </span>
            <label for="grado_0">0</label>
            <RadioButton
              value="0"
              v-model="data_datos_eco_obstetrico.placenta_grado_madurez"
              v-tooltip.top="'0'"
              class="mr-2"
            />
            <label for="grado_I"><strong>I</strong></label>
            <RadioButton
              value="I"
              v-model="data_datos_eco_obstetrico.placenta_grado_madurez"
              v-tooltip.top="'I'"
              class="mr-2"
            />
            <label for="grado_II"><strong>II</strong></label>
            <RadioButton
              value="II"
              v-model="data_datos_eco_obstetrico.placenta_grado_madurez"
              v-tooltip.top="'2'"
              class="mr-2"
            />
            <label for="grado_III"><strong>III</strong></label>
            <RadioButton
              value="III"
              v-model="data_datos_eco_obstetrico.placenta_grado_madurez"
              v-tooltip.top="'III'"
              class="mr-2"
            />
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup flex align-items-center">
            <span class="titulo-izquierdo mr-2">
              <strong>Peso Apróximado: </strong>
            </span>
            <Textarea
              v-model="data_datos_eco_obstetrico.peso_aproximado"
              v-tooltip.top="'Peso Apróximado'"
              rows="1"
              autoResize
              class="style-textarea"
            />
          </div>
        </div>
      </div>

      <div class="p-fluid formgrid grid">
        <div class="col-12 md:col-12 ml-2 color-linea">
          <div class="field col-12 md:col-12">
            <div class="p-inputgroup flex align-items-center">
              <span>
                <strong>CONCLUSIÓN: </strong>
              </span>
              <Textarea
                v-model="data_datos_eco_obstetrico.conclusion"
                v-tooltip.top="'Conclusión'"
                rows="1"
                autoResize
                class="style-textarea"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="grid mt-2 justify-content-end">
        <Button
          label="LIMPIAR"
          icon="pi pi-refresh"
          class="p-button-lg p-button-secondary"
          v-tooltip.top="'Limpiar Formulario'"
          @click="(data_datos_eco_obstetrico = {}), (fecha_seleccionada = null)"
        />
        <Button
          label="IMPRIMIR"
          icon="pi pi-print"
          class="p-button-lg p-button-danger ml-2"
          v-tooltip.top="'Imprimir Informe Obstétrico'"
          @click="printEcoObstetrico()"
          :loading="imprimiendo"
          :disabled="this.fecha_seleccionada == null"
        />
        <Button
          label="GUARDAR ECO OBSTÉTRICO"
          icon="pi pi-save"
          class="p-button-lg p-button-success ml-2"
          v-tooltip.top="'Guardar Informe Obstétrico'"
          :disabled="fecha_seleccionada != null"
          @click="modalConfirmacion()"
          :loading="loading"
        >
        </Button>
      </div>
    </div>
    <Dialog
      v-model:visible="displayModal"
      modal
      header="Confirmación"
      :style="{ width: '450px' }"
    >
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-12">
          <div class="p-inputgroup flex align-items-center">
            <span style="font-size: 12px; font-weight: bold; color: red">
              <strong>NOTA: </strong> Una vez guardado el Informe Obstétrico no
              se podrá modificar.
            </span>
          </div>
          <div class="p-inputgroup flex align-items-center">
            <i
              class="pi pi-exclamation-triangle mr-2"
              style="font-size: 3rem"
            />
            <h4><strong>¿Desea Guardar el Informe Obstétrico?</strong></h4>
          </div>
        </div>
      </div>
      <div class="grid justify-content-end">
        <Button
          label="NO, CANCELAR"
          icon="pi pi-times"
          class="p-button-lg p-button-danger mr-2"
          @click="cancelarEnvio()"
        />
        <Button
          label="SI, GUARDAR"
          icon="pi pi-save"
          class="p-button-lg p-button-success"
          @click="saveEcoObstetrico()"
          :loading="loading"
        />
      </div>
    </Dialog>
  </div>
</template>
<script>
import HistoriaService from "@/service/HistoriaService.js";
import { useAuth } from "@/stores";

export default {
  props: {
    datos_eco_obstetrico: {
      type: Object,
      default: () => {},
    },
    eco_obstetricos_history: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      data_datos_eco_obstetrico: this.datos_eco_obstetrico,
      loading: false,
      data_eco_obstetricos_history: this.eco_obstetricos_history,
      fechas: [
        {
          name: "Cargando...",
          value: "Cargando...",
        },
      ],
      fecha_seleccionada: null,
      id: null,
      displayModal: false,
      imprimiendo: false,
    };
  },
  historiaService: null,
  auth: null,

  created() {
    this.historiaService = new HistoriaService();
    this.auth = useAuth();
  },
  methods: {
    modalConfirmacion() {
      this.displayModal = true;
    },
    cancelarEnvio() {
      this.displayModal = false;
      this.$toast.add({
        severity: "info",
        summary: "Información",
        detail: "Se cancelo el envio del Informe Obstétrico",
        life: 3000,
      });
    },
    printEcoObstetrico() {
      let datos = {
        cod_informe: this.id,
      };
      this.imprimiendo = true;
      this.historiaService.printEcoObstetrico(datos).then(() => {
        this.imprimiendo = false;
      });
    },
    fechaSeleccionada(fecha) {
      this.fecha_seleccionada = fecha;
      this.data_datos_eco_obstetrico = this.data_eco_obstetricos_history.find(
        (item) => item.fecha_reg == fecha
      );
      this.id = this.data_datos_eco_obstetrico.id;
    },
    saveEcoObstetrico() {
      this.loading = true;
      let datos = {
        id_historia: this.data_datos_eco_obstetrico.id || null,
        cliente_id: this.data_datos_eco_obstetrico.cliente_id || null,
        nombre_cliente: this.data_datos_eco_obstetrico.nombre_cliente || null,
        medico_id: this.data_datos_eco_obstetrico.medico_id || null,
        nombre_medico: this.data_datos_eco_obstetrico.nombre_medico || null,
        ...this.data_datos_eco_obstetrico,
      };

      this.historiaService
        .saveEcoObstetrico(datos)
        .then((response) => {
          this.loading = false;
          this.$toast.add({
            severity: "success",
            summary: "Historia Guardada",
            detail: response.message,
            life: 3000,
          });
          /* if (this.auth.user.name != "ENFERMERIA") {
            this.id = response.id_creado;
            this.printEcoObstetrico();
          } */
          this.$router.push({
            name: "fichas_consultas",
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: error.message,
            life: 3000,
          });
        });
    },
  },
  watch: {
    datos_eco_obstetrico(val) {
      this.data_datos_eco_obstetrico = val;
    },
    eco_obstetricos_history(val) {
      this.data_eco_obstetricos_history = val;
      this.fechas = this.data_eco_obstetricos_history.map((item) => {
        return {
          name: item.fecha_reg,
          value: item.fecha_reg,
        };
      });

      this.fechas.forEach((item) => {
        item.name = item.name.split(" ")[0];
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.p-invalid {
  color: red;
}
.p-float-label {
  .p-inputtextarea {
    color: #0062ff;
    text-transform: capitalize;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px dotted #00346b;
    &:focus {
      border-bottom: px solid #00346b;
    }
  }
}
.p-float-label label {
  color: #003a6e;
}
.p-divider {
  font-weight: bold;
  color: #003a6e;
}
.p-radiobutton-label {
  font-weight: bold;
  color: #003a6e;
}
.color-linea {
  border: 2px solid #003a6e;
  border-radius: 5px;
}
.titulo-izquierdo {
  font-weight: bold;
  color: #003a6e;
}
.texto-span {
  font-weight: bold;
  color: #003a6e;
}
.style-textarea {
  color: #0062ff;
  text-transform: capitalize;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px dotted #00346b;
  &:focus {
    border-bottom: px solid #00346b;
  }
}
</style>