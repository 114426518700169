<template>
  <div class="grid">
    <div class="col-12 md:col-1 color-linea">
      <span style="font-size: 12px; font-weight: bold">HISTORICO</span>
      <div
        v-for="(item, index) in fechas"
        :key="index"
        @click="fechaSeleccionada(item.value)"
        :class="
          item.value == data_datos_eco_ginecologico.fecha_reg
            ? 'bg-gray-200'
            : ''
        "
        style="border: 1px solid #ccc; border-top: none; cursor: pointer"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="col-12 md:col-11">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span"> Gestaciones: </span>
            <span class="p-float-label">
              <Textarea
                v-model="data_datos_eco_ginecologico.gestaciones"
                v-tooltip.top="'Gestaciones'"
                rows="1"
                autoResize
              />
              <label for="inputtext"></label>
            </span>
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span"> Partos: </span>
            <span class="p-float-label">
              <Textarea
                v-model="data_datos_eco_ginecologico.partos"
                v-tooltip.top="'Partos'"
                rows="1"
                autoResize
              />
              <label for="inputtext"></label>
            </span>
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span"> Abortos: </span>
            <span class="p-float-label">
              <Textarea
                v-model="data_datos_eco_ginecologico.abortos"
                v-tooltip.top="'Abortos'"
                rows="1"
                autoResize
              />
              <label for="inputtext"></label>
            </span>
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span"> Cesáreas: </span>
            <span class="p-float-label">
              <Textarea
                v-model="data_datos_eco_ginecologico.cesareas"
                v-tooltip.top="'Cesareas'"
                rows="1"
                autoResize
              />
              <label for="inputtext"></label>
            </span>
          </div>
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span"> FUM: </span>
            <span class="p-float-label">
              <Textarea
                v-model="data_datos_eco_ginecologico.fum"
                v-tooltip.top="'FUM'"
                rows="1"
                autoResize
              />
              <label for="inputtext"></label>
            </span>
          </div>
        </div>
        <div class="field col-12 md:col-9">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span"> Diagnósticosclínicos: </span>
            <span class="p-float-label">
              <Textarea
                v-model="data_datos_eco_ginecologico.diagnosticos_clinicos"
                v-tooltip.top="'Diagnósticosclínicos'"
                rows="1"
                autoResize
              />
              <label for="inputtext"></label>
            </span>
          </div>
        </div>
      </div>
      <p class="titulo-izquierdo">
        <strong>A LOS DIFERENTES CORTES Y BARRIDOS, SE VISUALIZA:</strong>
      </p>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-1">
          <p class="titulo-izquierdo"><strong>VEJIGA:</strong></p>
        </div>
        <div class="field col-12 md:col-6">
          <div class="p-inputgroup flex align-items-center">
            <label for="llena">Llena</label>
            <RadioButton
              inputId="llena"
              value="Llena"
              v-model="data_datos_eco_ginecologico.vejiga"
              v-tooltip.top="'Llena'"
              class="mr-2"
            />
            <label for="semillena">Semillena</label>
            <RadioButton
              inputId="semillena"
              value="Semillena"
              v-model="data_datos_eco_ginecologico.vejiga"
              v-tooltip.top="'Semillena'"
              class="mr-2"
            />
            <label for="vacia">Vacia</label>
            <RadioButton
              inputId="vacia"
              value="Vacia"
              v-model="data_datos_eco_ginecologico.vejiga"
              v-tooltip.top="'Vacia'"
              class="mr-2"
            />
          </div>
        </div>
        <div class="field col-12 md:col-5">
          <div class="p-inputgroup flex align-items-center">
            <span class="mr-2">
              <strong class="mt-2">Aspecto Normal: </strong>
            </span>
            <label for="aspecto_normal">SI</label>
            <RadioButton
              inputId="aspecto_normal"
              value="Si"
              v-model="data_datos_eco_ginecologico.vejiga_aspecto_normal"
              v-tooltip.top="'SI'"
              class="mr-2"
            />
            <label for="aspecto_normal_no">NO</label>
            <RadioButton
              inputId="aspecto_normal_no"
              value="No"
              v-model="data_datos_eco_ginecologico.vejiga_aspecto_normal"
              v-tooltip.top="'NO'"
              class="mr-2"
            />
          </div>
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-1">
          <p class="titulo-izquierdo"><strong>ÚTERO:</strong></p>
        </div>
        <div class="field col-12 md:col-1">
          <p class="mt-2"><strong>Diamentro:</strong></p>
        </div>
        <div class="field col-12 md:col-3">
          <span class="p-float-label">
            <Textarea
              v-model="data_datos_eco_ginecologico.utero_long"
              v-tooltip.top="'Long'"
              rows="1"
              autoResize
            />
            <label for="inputtext">Long...</label>
          </span>
        </div>
        <div class="field col-12 md:col-3">
          <span class="p-float-label">
            <Textarea
              v-model="data_datos_eco_ginecologico.utero_ap"
              v-tooltip.top="'A.P'"
              rows="1"
              autoResize
            />
            <label for="inputtext">A.P...</label>
          </span>
        </div>
        <div class="field col-12 md:col-4">
          <span class="p-float-label">
            <Textarea
              v-model="data_datos_eco_ginecologico.utero_tr"
              v-tooltip.top="'T.R'"
              rows="1"
              autoResize
            />
            <label for="inputtext">T.R...</label>
          </span>
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-1"></div>
        <div class="field col-12 md:col-6">
          <div class="p-inputgroup flex align-items-center">
            <label for="anteversion">Anteversión</label>
            <RadioButton
              inputId="anteversion"
              value="Anteversion"
              v-model="data_datos_eco_ginecologico.utero_an_re_me"
              v-tooltip.top="'Anteversión'"
              class="mr-2"
            />
            <label for="retroversion">Retroversión</label>
            <RadioButton
              inputId="retroversion"
              value="Retroversion"
              v-model="data_datos_eco_ginecologico.utero_an_re_me"
              v-tooltip.top="'Retroversión'"
              class="mr-2"
            />
            <label for="medioversion">Medioversión</label>
            <RadioButton
              inputId="medioversion"
              value="Medioversion"
              v-model="data_datos_eco_ginecologico.utero_an_re_me"
              v-tooltip.top="'Medioversión'"
              class="mr-2"
            />
          </div>
        </div>
        <div class="field col-12 md:col-5">
          <div class="p-inputgroup flex align-items-center">
            <span class="mr-2">
              <strong class="mt-2">Contornos: </strong>
            </span>
            <label for="regulares">Regulares</label>
            <RadioButton
              inputId="regulares"
              value="Regulares"
              v-model="data_datos_eco_ginecologico.utero_contornos"
              v-tooltip.top="'Regulares'"
              class="mr-2"
            />
            <label for="irregulares">Irregulares</label>
            <RadioButton
              inputId="irregulares"
              value="Irregulares"
              v-model="data_datos_eco_ginecologico.utero_contornos"
              v-tooltip.top="'Irregulares'"
              class="mr-2"
            />
          </div>
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-1">
          <p class="titulo-izquierdo"><strong>Contenido:</strong></p>
        </div>
        <div class="field col-12 md:col-4">
          <div class="p-inputgroup flex align-items-center">
            <span class="mr-2">
              <strong class="mt-2">MIOMETRIO: </strong>
            </span>
            <label for="homogeneo">Homogéneo</label>
            <RadioButton
              inputId="homogeneo"
              value="Homogeneo"
              v-model="data_datos_eco_ginecologico.contenido_miometrio"
              v-tooltip.top="'Homogéneo'"
              class="mr-2"
            />
            <label for="heterogeneo">Heterogéneo</label>
            <RadioButton
              inputId="heterogeneo"
              value="Heterogeneo"
              v-model="data_datos_eco_ginecologico.contenido_miometrio"
              v-tooltip.top="'Heterogéneo'"
              class="mr-2"
            />
          </div>
        </div>
        <div class="field col-12 md:col-4">
          <div class="p-inputgroup flex align-items-center">
            <span class="mr-2"><strong>ENDOMETRIO:</strong></span>
            <span class="mr-2">
              <strong>Delimitado</strong>
            </span>
            <label for="si">SI</label>
            <RadioButton
              inputId="si"
              value="Si"
              v-model="data_datos_eco_ginecologico.contenido_endometrio"
              v-tooltip.top="'SI'"
              class="mr-2"
            />
            <label for="no">NO</label>
            <RadioButton
              inputId="no"
              value="No"
              v-model="data_datos_eco_ginecologico.contenido_endometrio"
              v-tooltip.top="'NO'"
              class="mr-2"
            />
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <span class="p-float-label">
            <Textarea
              v-model="data_datos_eco_ginecologico.contenido_midiendo"
              v-tooltip.top="'Midiendo'"
              rows="1"
              autoResize
            />
            <label for="inputtext">Midiendo...</label>
          </span>
        </div>
        <div class="field col-12 md:col-1"></div>
        <div class="field col-12 md:col-4">
          <div class="p-inputgroup flex align-items-center">
            <span class="mr-2"><strong>CONTENIDO UTERINO</strong></span>
            <label for="si">SI</label>
            <RadioButton
              inputId="si"
              value="Si"
              v-model="data_datos_eco_ginecologico.contenido_uterino"
              v-tooltip.top="'SI'"
              class="mr-2"
            />
            <label for="no">NO</label>
            <RadioButton
              inputId="no"
              value="No"
              v-model="data_datos_eco_ginecologico.contenido_uterino"
              v-tooltip.top="'NO'"
              class="mr-2"
            />
          </div>
        </div>
        <div class="field col-12 md:col-7">
          <span class="p-float-label">
            <Textarea
              v-model="data_datos_eco_ginecologico.contenido_descripcion"
              v-tooltip.top="'Descripción'"
              rows="1"
              autoResize
            />
            <label for="inputtext">Descripción...</label>
          </span>
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-1">
          <p class="titulo-izquierdo"><strong>ANEXOS:</strong></p>
        </div>
        <div class="field col-12 md:col-11">
          <span class="p-float-label">
            <Textarea
              v-model="data_datos_eco_ginecologico.anexos"
              v-tooltip.top="'Descripción'"
              rows="1"
              autoResize
            />
            <label for="inputtext">Descripción de Anexos...</label>
          </span>
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-1">
          <p class="titulo-izquierdo"><strong>OVARIOS:</strong></p>
        </div>
        <div class="field col-12 md:col-4">
          <span class="p-float-label">
            <Textarea
              v-model="data_datos_eco_ginecologico.ovarios_der"
              v-tooltip.top="'Derecho'"
              rows="1"
              autoResize
            />
            <label for="inputtext">Derecho...</label>
          </span>
        </div>
        <div class="field col-12 md:col-3">
          <span class="p-float-label">
            <Textarea
              v-model="data_datos_eco_ginecologico.ovarios_izq"
              v-tooltip.top="'Izquierdo'"
              rows="1"
              autoResize
            />
            <label for="inputtext">Izquierdo...</label>
          </span>
        </div>
        <div class="field col-12 md:col-4">
          <span class="p-float-label">
            <Textarea
              v-model="data_datos_eco_ginecologico.ovarios_quiste"
              v-tooltip.top="'Quiste'"
              rows="1"
              autoResize
            />
            <label for="inputtext">Quiste...</label>
          </span>
        </div>
        <div class="field col-12 md:col-1"></div>
        <div class="field col-12 md:col-5">
          <span class="p-float-label">
            <Textarea
              v-model="data_datos_eco_ginecologico.fondo_saco_posterior"
              v-tooltip.top="'Fondo de Saco Posterior'"
              rows="1"
              autoResize
            />
            <label for="inputtext">Fondo de Saco Posterior...</label>
          </span>
        </div>
        <div class="field col-12 md:col-5">
          <span class="p-float-label">
            <Textarea
              v-model="data_datos_eco_ginecologico.otros"
              v-tooltip.top="'Otros'"
              rows="1"
              autoResize
            />
            <label for="inputtext">Otros...</label>
          </span>
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="col-12 md:col-12 ml-2 color-linea">
          <div class="field col-12 md:col-12">
            <div class="p-inputgroup flex align-items-center">
              <span>
                <strong>CONCLUSIÓN: </strong>
              </span>
              <span class="p-float-label">
                <Textarea
                  v-model="data_datos_eco_ginecologico.conclusion"
                  v-tooltip.top="'Conclusión'"
                  rows="1"
                  autoResize
                />
                <label for="inputtext"></label>
              </span>
            </div>
          </div>
          <div class="field col-12 md:col-12">
            <div class="p-inputgroup flex align-items-center">
              <span>
                <strong>Contenido: </strong>
              </span>
              <span class="p-float-label">
                <Textarea
                  v-model="data_datos_eco_ginecologico.conclusion_contenido"
                  v-tooltip.top="'Conclusión Contenido'"
                  rows="1"
                  autoResize
                />
                <label for="inputtext"></label>
              </span>
            </div>
          </div>
          <div class="field col-12 md:col-12">
            <div class="p-inputgroup flex align-items-center">
              <span>
                <strong>Miometrio: </strong>
              </span>
              <span class="p-float-label">
                <Textarea
                  v-model="data_datos_eco_ginecologico.conclusion_miometrio"
                  v-tooltip.top="'Conclusión Miometrio'"
                  rows="1"
                  autoResize
                />
                <label for="inputtext"></label>
              </span>
            </div>
          </div>
          <div class="field col-12 md:col-12">
            <div class="p-inputgroup flex align-items-center">
              <span>
                <strong>Endometrio: </strong>
              </span>
              <span class="p-float-label">
                <Textarea
                  v-model="data_datos_eco_ginecologico.conclusion_endometrio"
                  v-tooltip.top="'Conclusión Endometrio'"
                  rows="1"
                  autoResize
                />
                <label for="inputtext"></label>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="grid mt-2 justify-content-end">
        <Button
          label="LIMPIAR"
          icon="pi pi-refresh"
          class="p-button-lg p-button-secondary"
          v-tooltip.top="'Limpiar Formulario'"
          @click="
            (data_datos_eco_ginecologico = {}), (fecha_seleccionada = null)
          "
        />
        <Button
          label="IMPRIMIR"
          icon="pi pi-print"
          class="p-button-lg p-button-danger ml-2"
          v-tooltip.top="'Imprimir Informe Ginecológico'"
          @click="printEcoGinecologico()"
          :disabled="fecha_seleccionada == null"
          :loading="printEco"
        />
        <Button
          label="GUARDAR ECO GINECOLÓGICO"
          icon="pi pi-save"
          class="p-button-lg p-button-success ml-2"
          v-tooltip.top="'Guardar Informe Ginecológico'"
          :disabled="fecha_seleccionada != null"
          @click="modalConfirmacion()"
          :loading="loading"
        >
        </Button>
      </div>
    </div>
    <Dialog
      v-model:visible="displayModal"
      modal
      header="Confirmación"
      :style="{ width: '450px' }"
    >
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-12">
          <div class="p-inputgroup flex align-items-center">
            <span style="font-size: 12px; font-weight: bold; color: red">
              <strong>NOTA: </strong> Una vez guardado el Informe Ginecológico
              no se podrá modificar.
            </span>
          </div>
          <div class="p-inputgroup flex align-items-center">
            <i
              class="pi pi-exclamation-triangle mr-2"
              style="font-size: 3rem"
            />
            <h4><strong>¿Desea Guardar el Informe Ginecológico?</strong></h4>
          </div>
        </div>
      </div>
      <div class="grid justify-content-end">
        <Button
          label="NO, CANCELAR"
          icon="pi pi-times"
          class="p-button-lg p-button-danger mr-2"
          @click="cancelarEnvio()"
        />
        <Button
          label="SI, GUARDAR"
          icon="pi pi-save"
          class="p-button-lg p-button-success"
          @click="saveEcoGinecologico()"
        />
      </div>
    </Dialog>
  </div>
</template>
<script>
import HistoriaService from "@/service/HistoriaService.js";
import { useAuth } from "@/stores";

export default {
  props: {
    datos_eco_ginecologico: {
      type: Object,
      default: () => {},
    },
    eco_ginecologicos_history: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      data_datos_eco_ginecologico: this.datos_eco_ginecologico,
      loading: false,
      data_eco_abdominals_history: this.eco_ginecologicos_history,
      fechas: [
        {
          name: "Cargando...",
          value: "Cargando...",
        },
      ],
      fecha_seleccionada: null,
      id: null,
      displayModal: false,
      printEco: false,
    };
  },
  historiaService: null,
  auth: null,

  created() {
    this.historiaService = new HistoriaService();
    this.auth = useAuth();
  },
  methods: {
    modalConfirmacion() {
      this.displayModal = true;
    },
    cancelarEnvio() {
      this.displayModal = false;
      this.$toast.add({
        severity: "info",
        summary: "Información",
        detail: "Se cancelo el envio del Informe Ginecológico",
        life: 3000,
      });
    },
    printEcoGinecologico() {
      let datos = {
        cod_informe: this.id,
      };
      this.printEco = true;
      this.historiaService.printEcoGinecologico(datos).then(() => {
        this.printEco = false;
      });
    },
    fechaSeleccionada(fecha) {
      this.fecha_seleccionada = fecha;
      this.data_datos_eco_ginecologico = this.data_eco_abdominals_history.find(
        (item) => item.fecha_reg == fecha
      );
      this.id = this.data_datos_eco_ginecologico.id;
    },
    saveEcoGinecologico() {
      this.loading = true;
      let datos = {
        id_historia: this.data_datos_eco_ginecologico.id || null,
        cliente_id: this.data_datos_eco_ginecologico.cliente_id || null,
        nombre_cliente: this.data_datos_eco_ginecologico.nombre_cliente || null,
        medico_id: this.data_datos_eco_ginecologico.medico_id || null,
        nombre_medico: this.data_datos_eco_ginecologico.nombre_medico || null,
        ...this.data_datos_eco_ginecologico,
      };

      this.historiaService
        .saveEcoGinecologico(datos)
        .then((response) => {
          this.loading = false;
          this.$toast.add({
            severity: "success",
            summary: "Historia Guardada",
            detail: response.message,
            life: 3000,
          });
          /* if (this.auth.user.name != "ENFERMERIA") {
            this.id = response.id_creado;
            this.printEcoGinecologico();
          } */
          this.$router.push({
            name: "fichas_consultas",
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: error.message,
            life: 3000,
          });
        });
    },
  },
  watch: {
    datos_eco_ginecologico(val) {
      this.data_datos_eco_ginecologico = val;
    },
    eco_ginecologicos_history(val) {
      this.data_eco_abdominals_history = val;
      this.fechas = this.data_eco_abdominals_history.map((item) => {
        return {
          name: item.fecha_reg,
          value: item.fecha_reg,
        };
      });

      this.fechas.forEach((item) => {
        item.name = item.name.split(" ")[0];
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.p-invalid {
  color: red;
}
.p-float-label {
  .p-inputtextarea {
    color: #0062ff;
    text-transform: capitalize;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px dotted #00346b;
    &:focus {
      border-bottom: px solid #00346b;
    }
  }
}
.p-float-label label {
  color: #003a6e;
}
.p-divider {
  font-weight: bold;
  color: #003a6e;
}
.p-radiobutton-label {
  font-weight: bold;
  color: #003a6e;
}
.color-linea {
  border: 2px solid #003a6e;
  border-radius: 5px;
}
.titulo-izquierdo {
  font-weight: bold;
  color: #003a6e;
}
.texto-span {
  font-weight: bold;
  color: #003a6e;
}
</style>