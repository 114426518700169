<template>
  <div class="grid">
    <div class="col-12 md:col-1 color-linea">
      <span style="font-size: 12px; font-weight: bold">HISTORICO</span>
      <div
        v-for="(item, index) in fechas"
        :key="index"
        @click="fechaSeleccionada(item.value)"
        :class="
          item.value == data_datos_eco_monitoreofetal.fecha_reg
            ? 'bg-gray-200'
            : ''
        "
        style="border: 1px solid #ccc; border-top: none; cursor: pointer"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="col-12 md:col-11">
      <div class="p-fluid grid">
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup flex align-items-center">
            <span class="titulo-izquierdo">F.U.M.: </span>
            <input
              type="date"
              v-model="data_datos_eco_monitoreofetal.fum"
              v-tooltip.top="'F.U.M.'"
              class="p-inputtext p-component p-filled"
              @change="calcularFechaProbableParto(), calcularEdadGestacional()"
            />
          </div>
        </div>
        <div class="field col-12 md:col-5">
          <div class="p-inputgroup flex align-items-center">
            <span class="titulo-izquierdo mr-2"
              >FECHA PROBABLE DE PARTO:
              <span class="estilos-fecha-probable-parto">
                {{
                  textoFecha(
                    data_datos_eco_monitoreofetal.fecha_probable_parto
                  ) ?? "-"
                }}
              </span>
            </span>
          </div>
        </div>
        <div class="field col-12 md:col-4">
          <div class="p-inputgroup flex align-items-center">
            <span class="titulo-izquierdo mr-2"
              >EDAD GESTACIONAL:
              <span class="estilos-edad-gestacional">
                {{ data_datos_eco_monitoreofetal.edad_gestacional ?? "-" }}
              </span>
            </span>
          </div>
        </div>
      </div>

      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-4">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span"> Frecuencia Cardica Materna: </span>
            <Textarea
              v-model="
                data_datos_eco_monitoreofetal.frecuencia_cardiaca_materna
              "
              v-tooltip.top="'Frecuencia Cardiaca Materna'"
              rows="1"
              autoResize
              class="style-textarea"
            />
            <span class="texto-span">Ipm</span>
          </div>
        </div>
        <div class="field col-12 md:col-4">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span"> Presión Arterial: </span>
            <Textarea
              v-model="data_datos_eco_monitoreofetal.presion_arterial"
              v-tooltip.top="' Presión Arterial'"
              rows="1"
              autoResize
              class="style-textarea"
            />
            <span class="texto-span">mmHg</span>
          </div>
        </div>
        <div class="field col-12 md:col-4">
          <div class="p-inputgroup flex align-items-center">
            <span class="texto-span">Frecuencia Cardiaca Fetal: </span>
            <Textarea
              v-model="data_datos_eco_monitoreofetal.frecuencia_cardiaca_fetal"
              v-tooltip.top="'Frecuencia Cardiaca Fetal'"
              rows="1"
              autoResize
              class="style-textarea"
            />
            <span class="texto-span">Ipm</span>
          </div>
        </div>
      </div>

      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-6">
          <div class="p-inputgroup flex align-items-center">
            <span>
              <strong>Movimientos Fetales:</strong>
            </span>
            <Textarea
              v-model="data_datos_eco_monitoreofetal.movimientos_fetales"
              v-tooltip.top="'Movimientos Fetales'"
              rows="1"
              autoResize
              class="style-textarea"
            />
          </div>
        </div>
        <div class="field col-12 md:col-6">
          <div class="p-inputgroup flex align-items-center">
            <span>
              <strong>Dinámica Uterina: </strong>
            </span>
            <Textarea
              v-model="data_datos_eco_monitoreofetal.dinamica_uterina"
              v-tooltip.top="'Dinamica Uterina'"
              rows="1"
              autoResize
              class="style-textarea"
            />
          </div>
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-6">
          <div class="p-inputgroup flex align-items-center">
            <span>
              <strong>Variabilidad: </strong>
            </span>
            <Textarea
              v-model="data_datos_eco_monitoreofetal.variabilidad"
              v-tooltip.top="'Variabilidad'"
              rows="1"
              autoResize
              class="style-textarea"
            />
          </div>
        </div>
        <div class="field col-12 md:col-6">
          <div class="p-inputgroup flex align-items-center">
            <span>
              <strong>Reactividad: </strong>
            </span>
            <Textarea
              v-model="data_datos_eco_monitoreofetal.reactividad"
              v-tooltip.top="'Reactividad'"
              rows="1"
              autoResize
              class="style-textarea"
            />
          </div>
        </div>
      </div>

      <div class="p-fluid formgrid grid">
        <div class="col-12 md:col-12 ml-2 color-linea">
          <div class="field col-12 md:col-12">
            <div class="p-inputgroup flex align-items-center">
              <span>
                <strong>CONCLUSIÓN: </strong>
              </span>
              <Textarea
                v-model="data_datos_eco_monitoreofetal.conclusion"
                v-tooltip.top="'Conclusión'"
                rows="1"
                autoResize
                class="style-textarea"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="grid mt-2 justify-content-end">
        <Button
          label="LIMPIAR"
          icon="pi pi-refresh"
          class="p-button-lg p-button-secondary"
          v-tooltip.top="'Limpiar Formulario'"
          @click="
            (data_datos_eco_monitoreofetal = {}), (fecha_seleccionada = null)
          "
        />
        <Button
          label="IMPRIMIR"
          icon="pi pi-print"
          class="p-button-lg p-button-danger ml-2"
          v-tooltip.top="'Imprimir Monitoreo Fetal'"
          @click="printEcoMonitoreoFetal()"
          :loading="printing"
          :disabled="fecha_seleccionada == null"
        />

        <Button
          label="GUARDAR MONITOREO FETAL"
          icon="pi pi-save"
          class="p-button-lg p-button-success ml-2"
          v-tooltip.top="'Guardar Monitoreo Fetal'"
          :disabled="fecha_seleccionada != null"
          @click="modalConfirmacion()"
          :loading="loading"
        >
        </Button>
      </div>
    </div>
    <Dialog
      v-model:visible="displayModal"
      modal
      header="Confirmación"
      :style="{ width: '450px' }"
    >
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-12">
          <div class="p-inputgroup flex align-items-center">
            <span style="font-size: 12px; font-weight: bold; color: red">
              <strong>NOTA: </strong> Una vez guardado el Monitoreo Fetal no se
              podrá modificar.
            </span>
          </div>
          <div class="p-inputgroup flex align-items-center">
            <i
              class="pi pi-exclamation-triangle mr-2"
              style="font-size: 3rem"
            />
            <h4><strong>¿Desea Guardar el Monitoreo Fetal?</strong></h4>
          </div>
        </div>
      </div>
      <div class="grid justify-content-end">
        <Button
          label="NO, CANCELAR"
          icon="pi pi-times"
          class="p-button-lg p-button-danger mr-2"
          @click="cancelarEnvio()"
        />
        <Button
          label="SI, GUARDAR"
          icon="pi pi-save"
          class="p-button-lg p-button-success"
          @click="saveEcoMonitoreoFetal()"
          :loading="loading"
        />
      </div>
    </Dialog>
  </div>
</template>
<script>
import HistoriaService from "@/service/HistoriaService.js";
import { useAuth } from "@/stores";

export default {
  props: {
    datos_eco_monitoreofetal: {
      type: Object,
      default: () => {},
    },
    eco_monitoreofetals_history: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      data_datos_eco_monitoreofetal: this.datos_eco_monitoreofetal,
      loading: false,
      data_eco_monitoreofetals_history: this.eco_monitoreofetals_history,
      fechas: [
        {
          name: "Cargando...",
          value: "Cargando...",
        },
      ],
      fecha_seleccionada: null,
      id: null,
      displayModal: false,
      printing: false,
    };
  },
  historiaService: null,
  auth: null,

  created() {
    this.historiaService = new HistoriaService();
    this.auth = useAuth();
  },
  methods: {
    modalConfirmacion() {
      this.displayModal = true;
    },
    cancelarEnvio() {
      this.displayModal = false;
      this.$toast.add({
        severity: "info",
        summary: "Información",
        detail: "Se cancelo el envio del Monitoreo Fetal",
        life: 3000,
      });
    },
    textoFecha(fecha) {
      if (fecha) {
        let fecha_array = fecha.split("-");
        let dia = fecha_array[2];
        let mes = fecha_array[1];
        let anio = fecha_array[0];
        let meses = [
          "enero",
          "febrero",
          "marzo",
          "abril",
          "mayo",
          "junio",
          "julio",
          "agosto",
          "septiembre",
          "octubre",
          "noviembre",
          "diciembre",
        ];
        return `${dia} de ${meses[mes - 1]} del ${anio}`;
      }
    },
    printEcoMonitoreoFetal() {
      let datos = {
        cod_informe: this.id,
      };
      this.printing = true;
      this.historiaService.printEcoMonitoreoFetal(datos).then(() => {
        this.printing = false;
      });
    },
    calcularFechaProbableParto() {
      let fecha = new Date(this.data_datos_eco_monitoreofetal.fum);
      let fecha_probable_parto = new Date(
        fecha.getFullYear(),
        fecha.getMonth(),
        fecha.getDate() + 280
      );

      /* 2024-01-10 */
      let dia = fecha_probable_parto.getDate();
      let mes = fecha_probable_parto.getMonth() + 1;
      let anio = fecha_probable_parto.getFullYear();

      this.data_datos_eco_monitoreofetal.fecha_probable_parto = `${anio}-${mes}-${dia}`;
    },

    calcularEdadGestacional() {
      let fecha = new Date(this.data_datos_eco_monitoreofetal.fum);
      let fecha_actual = new Date();
      let edad_gestacional = fecha_actual.getTime() - fecha.getTime();
      let dias = Math.round(edad_gestacional / (1000 * 60 * 60 * 24));
      let semanas = Math.floor(dias / 7);
      let diasRestantes = dias % 7;

      this.data_datos_eco_monitoreofetal.edad_gestacional = {
        semanas: semanas,
        dias: diasRestantes,
        totalDias: dias,
      };

      this.data_datos_eco_monitoreofetal.edad_gestacional = `${semanas} semanas y ${diasRestantes} dias`;
    },

    fechaSeleccionada(fecha) {
      this.fecha_seleccionada = fecha;
      this.data_datos_eco_monitoreofetal =
        this.data_eco_monitoreofetals_history.find(
          (item) => item.fecha_reg == fecha
        );

      this.id = this.data_datos_eco_monitoreofetal.id;
    },
    saveEcoMonitoreoFetal() {
      this.loading = true;
      let datos = {
        cliente_id: this.data_datos_eco_monitoreofetal.cliente_id || null,
        nombre_cliente:
          this.data_datos_eco_monitoreofetal.nombre_cliente || null,
        medico_id: this.data_datos_eco_monitoreofetal.medico_id || null,
        nombre_medico: this.data_datos_eco_monitoreofetal.nombre_medico || null,
        ...this.data_datos_eco_monitoreofetal,
      };

      this.historiaService
        .saveEcoMonitoreoFetal(datos)
        .then((response) => {
          this.loading = false;
          this.$toast.add({
            severity: "success",
            summary: "Exito",
            detail: response.message,
            life: 3000,
          });
          /* if (this.auth.user.name != "ENFERMERIA") {
            this.id = response.id_creado;
            this.printEcoMonitoreoFetal();
          } */
          this.$router.push({
            name: "fichas_consultas",
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: error.message,
            life: 3000,
          });
        });
    },
  },
  watch: {
    datos_eco_monitoreofetal(val) {
      this.data_datos_eco_monitoreofetal = val;
    },
    eco_monitoreofetals_history(val) {
      this.data_eco_monitoreofetals_history = val;
      this.fechas = this.data_eco_monitoreofetals_history.map((item) => {
        return {
          name: item.fecha_reg,
          value: item.fecha_reg,
        };
      });

      this.fechas.forEach((item) => {
        item.name = item.name.split(" ")[0];
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.p-invalid {
  color: red;
}
.p-float-label {
  .p-inputtextarea {
    color: #0062ff;
    text-transform: capitalize;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px dotted #00346b;
    &:focus {
      border-bottom: px solid #00346b;
    }
  }
}
.p-float-label label {
  color: #003a6e;
}
.p-divider {
  font-weight: bold;
  color: #003a6e;
}
.p-radiobutton-label {
  font-weight: bold;
  color: #003a6e;
}
.color-linea {
  border: 2px solid #003a6e;
  border-radius: 5px;
}
.titulo-izquierdo {
  font-weight: bold;
  color: #003a6e;
}
.texto-span {
  font-weight: bold;
  color: #003a6e;
}
.style-textarea {
  color: #0062ff;
  text-transform: capitalize;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px dotted #00346b;
  &:focus {
    border-bottom: px solid #00346b;
  }
}
.estilos-fecha-probable-parto {
  font-weight: bold;
  color: #ff7f00;
  font-size: 16px;
  text-shadow: 0px 2px 0px #dadada;
}
.estilos-edad-gestacional {
  font-weight: bold;
  color: #ff0000;
  font-size: 16px;
  text-shadow: 0px 2px 0px #dadada;
}
</style>