<template>
  <div class="grid">
    <div class="col-12 md:col-1 color-linea">
      <span style="font-size: 12px; font-weight: bold">HISTORICO</span>
      <div
        v-for="(item, index) in fechas"
        :key="index"
        @click="fechaSeleccionada(item.value)"
        :class="
          item.value == data_datos_eco_abdominal.fecha_reg ? 'bg-gray-200' : ''
        "
        style="border: 1px solid #ccc; border-top: none; cursor: pointer"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="col-12 md:col-11">
      <Divider align="left" type="dashed">
        <b>H&Iacute;GADO:</b>
      </Divider>
      <div class="p-fluid grid">
        <div class="field col-12 md:col-2">
          <span class="p-float-label">
            <Textarea
              v-model="data_datos_eco_abdominal.higado_contor"
              v-tooltip.top="'Contorno'"
              rows="1"
              autoResize
              autofocus
            />
            <label for="inputtext">Contornos...</label>
          </span>
        </div>
        <div class="field col-12 md:col-3">
          <span class="p-float-label">
            <Textarea
              v-model="data_datos_eco_abdominal.higado_estruc_parenquina"
              v-tooltip.top="'de Parénquima'"
              rows="1"
              autoResize
            />
            <label for="inputtext">Estructura del Parénquima...</label>
          </span>
        </div>
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup flex align-items-center">
            <span class="mr-2">
              <strong class="mt-2">Formaciones: </strong>
            </span>
            <label for="higado_solidas">Sólidas</label>
            <RadioButton
              inputId="higado_solidas"
              value="Sólidas"
              v-model="data_datos_eco_abdominal.higado_formaciones"
              v-tooltip.top="'Sólidas'"
              class="mr-2"
            />
            <label for="higado_quisticas">Quísticas</label>
            <RadioButton
              inputId="higado_quisticas"
              value="Quísticas"
              v-model="data_datos_eco_abdominal.higado_formaciones"
              v-tooltip.top="'Quísticas'"
              class="mr-2"
            />
          </div>
        </div>
        <div class="field col-12 md:col-2">
          <span class="p-float-label">
            <Textarea
              v-model="data_datos_eco_abdominal.higado_medidas"
              v-tooltip.top="'Medidas'"
              rows="1"
              autoResize
            />
            <label for="inputtext">Medidas...</label>
          </span>
        </div>
        <div class="field col-12 md:col-2">
          <span class="p-float-label">
            <Textarea
              v-model="data_datos_eco_abdominal.hidado_vias_biliares"
              v-tooltip.top="'Vías Biliares intrahep&aacute;ticas'"
              rows="1"
              autoResize
            />
            <label for="inputtext">Vías Biliares...</label>
          </span>
        </div>
      </div>
      <Divider align="left" type="dashed">
        <b>VESICULA BILIAR:</b>
      </Divider>
      <div class="p-fluid grid">
        <div class="field col-12 md:col-2">
          <span class="p-float-label">
            <Textarea
              id="medidas"
              v-model="data_datos_eco_abdominal.vesicula_medidas"
              v-tooltip.top="'Medidas'"
              rows="1"
              autoResize
            />
            <label for="inputtext">Medidas...</label>
          </span>
        </div>
        <div class="field col-12 md:col-4">
          <div class="p-inputgroup flex align-items-center">
            <span class="mr-2">
              <strong class="mt-2">PAREDES: </strong>
            </span>
            <label for="regulares">Regulares</label>
            <RadioButton
              inputId="regulares"
              value="Regulares"
              v-model="data_datos_eco_abdominal.vesicula_paredes"
              v-tooltip.top="'Regulares'"
              class="mr-2"
            />
            <label for="irregulares">Irregulares</label>
            <RadioButton
              inputId="irregulares"
              value="Irregulares"
              v-model="data_datos_eco_abdominal.vesicula_paredes"
              v-tooltip.top="'Irregulares'"
              class="mr-2"
            />
            <label for="gruesas">Gruesas</label>
            <RadioButton
              inputId="gruesas"
              value="Gruesas"
              v-model="data_datos_eco_abdominal.vesicula_paredes"
              v-tooltip.top="'Gruesas'"
              class="mr-2"
            />
          </div>
        </div>
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <Textarea
              v-model="data_datos_eco_abdominal.vesicula_concre_ecorre_con_ss"
              v-tooltip.top="
                'Contenido con concresiones ecorrefringentes con S.S:'
              "
              rows="1"
              autoResize
            />
            <label for="inputtext"
              >Contenido: Concresiones ecorrefringentes con S.S:...</label
            >
          </span>
        </div>
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup flex align-items-center">
            <span class="mr-2">
              <strong class="mt-2">Sedimiento Visible: </strong>
            </span>
            <label for="vesicula_sedimento">SI</label>
            <RadioButton
              inputId="vesicula_sedimento"
              value="Si"
              v-model="data_datos_eco_abdominal.vesicula_sedimento"
              v-tooltip.top="'SI'"
              class="mr-2"
            />
            <label for="vesicula_no">NO</label>
            <RadioButton
              inputId="vesicula_no"
              value="No"
              v-model="data_datos_eco_abdominal.vesicula_sedimento"
              v-tooltip.top="'NO'"
              class="mr-2"
            />
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup flex align-items-center">
            <span class="mr-2">
              <strong class="mt-2">Col&eacute;doco Visible: </strong>
            </span>
            <label for="vesicula_coledoco">SI</label>
            <RadioButton
              inputId="vesicula_coledoco"
              value="Si"
              v-model="data_datos_eco_abdominal.vesicula_coledoco"
              v-tooltip.top="'SI'"
              class="mr-2"
            />
            <label for="vesicula_coledoco_no">NO</label>
            <RadioButton
              inputId="vesicula_coledoco_no"
              value="No"
              v-model="data_datos_eco_abdominal.vesicula_coledoco"
              v-tooltip.top="'NO'"
              class="mr-2"
            />
          </div>
        </div>
      </div>
      <Divider align="left" type="dashed">
        <b>BAZO:</b>
      </Divider>
      <div class="p-fluid grid">
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <Textarea
              v-model="data_datos_eco_abdominal.bazo_estructura"
              v-tooltip.top="'Estructura'"
              rows="1"
              autoResize
            />
            <label for="inputtext">Estructura...</label>
          </span>
        </div>
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <Textarea
              v-model="data_datos_eco_abdominal.bazo_tamanio"
              v-tooltip.top="'Tamaño'"
              rows="1"
              autoResize
            />
            <label for="inputtext">Tamaño...</label>
          </span>
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="col-12 md:col-6 grid">
          <Divider align="left" type="dashed">
            <b>RIÑON DERECHO</b>
          </Divider>
          <div class="field col-12 md:col-4">
            <span class="p-float-label">
              <Textarea
                v-model="data_datos_eco_abdominal.rinion_der_medidas"
                v-tooltip.top="'Medidas'"
                rows="1"
                autoResize
              />
              <label for="inputtext">Medidas...</label>
            </span>
          </div>
          <div class="field col-12 md:col-4">
            <span class="p-float-label">
              <Textarea
                v-model="data_datos_eco_abdominal.rinion_der_estruc_cortical"
                v-tooltip.top="'Estructura Cortical'"
                rows="1"
                autoResize
              />
              <label for="inputtext">Estructura Cortical...</label>
            </span>
          </div>
          <div class="field col-12 md:col-4">
            <span class="p-float-label">
              <Textarea
                v-model="data_datos_eco_abdominal.rinion_der_estruct_medular"
                v-tooltip.top="'Estructura Medular'"
                rows="1"
                autoResize
              />
              <label for="inputtext">Estructura Medular...</label>
            </span>
          </div>
          <div class="field col-12 md:col-5">
            <div class="p-inputgroup flex align-items-center">
              <span class="mr-1">
                <strong class="mt-2">Cál. dilatados: </strong>
              </span>
              <label for="calices_dilatados">SI</label>
              <RadioButton
                inputId="calices_dilatados"
                value="Si"
                v-model="data_datos_eco_abdominal.rinion_der_calices_dilatados"
                v-tooltip.top="'SI'"
                class="mr-1"
              />
              <label for="calices_dilatados_no">NO</label>
              <RadioButton
                inputId="calices_dilatados_no"
                value="No"
                v-model="data_datos_eco_abdominal.rinion_der_calices_dilatados"
                v-tooltip.top="'NO'"
                class="mr-1"
              />
            </div>
          </div>
          <div class="field col-12 md:col-7">
            <div class="p-inputgroup flex align-items-center">
              <span class="mr-1">
                <strong class="mt-2">Quistes: </strong>
              </span>
              <label for="quistes">SI</label>
              <RadioButton
                inputId="rinion_quistes"
                value="Si"
                v-model="data_datos_eco_abdominal.rinion_der_quistes"
                v-tooltip.top="'SI'"
                class="mr-1"
              />
              <label for="no">NO</label>
              <RadioButton
                inputId="rinion_quistes"
                value="No"
                v-model="data_datos_eco_abdominal.rinion_der_quistes"
                v-tooltip.top="'NO'"
                class="mr-1"
              />
              <label for="unico">Único</label>
              <RadioButton
                inputId="rinion_quistes"
                value="Unico"
                v-model="data_datos_eco_abdominal.rinion_der_quistes"
                v-tooltip.top="'Único'"
                class="mr-1"
              />
              <label for="multiples">Múltiples</label>
              <RadioButton
                inputId="rinion_quistes"
                value="Multiples"
                v-model="data_datos_eco_abdominal.rinion_der_quistes"
                v-tooltip.top="'Múltiples'"
                class="mr-1"
              />
            </div>
          </div>
        </div>
        <Divider layout="vertical" />
        <div class="col-12 md:col-6 grid">
          <Divider align="left" type="dashed">
            <b>RIÑON IZQUIERDO</b>
          </Divider>
          <div class="field col-12 md:col-4">
            <span class="p-float-label">
              <Textarea
                v-model="data_datos_eco_abdominal.rinion_izq_medidas"
                v-tooltip.top="'Medidas'"
                rows="1"
                autoResize
              />
              <label for="inputtext">Medidas...</label>
            </span>
          </div>
          <div class="field col-12 md:col-4">
            <span class="p-float-label">
              <Textarea
                v-model="data_datos_eco_abdominal.rinion_izq_estruc_cortical"
                v-tooltip.top="'Estructura Cortical'"
                rows="1"
                autoResize
              />
              <label for="inputtext">Estructura Cortical...</label>
            </span>
          </div>
          <div class="field col-12 md:col-4">
            <span class="p-float-label">
              <Textarea
                v-model="data_datos_eco_abdominal.rinion_izq_estruct_medular"
                v-tooltip.top="'Estructura Medular'"
                rows="1"
                autoResize
              />
              <label for="inputtext">Estructura Medular...</label>
            </span>
          </div>
          <div class="field col-12 md:col-5">
            <div class="p-inputgroup flex align-items-center">
              <span class="mr-1">
                <strong class="mt-2">Cál. dilatados: </strong>
              </span>
              <label for="calices_dilatados">SI</label>
              <RadioButton
                inputId="calices_dilatados"
                value="Si"
                v-model="data_datos_eco_abdominal.rinion_izq_calices_dilatados"
                v-tooltip.top="'SI'"
                class="mr-1"
              />
              <label for="calices_dilatados_no">NO</label>
              <RadioButton
                inputId="calices_dilatados_no"
                value="No"
                v-model="data_datos_eco_abdominal.rinion_izq_calices_dilatados"
                v-tooltip.top="'NO'"
                class="mr-1"
              />
            </div>
          </div>
          <div class="field col-12 md:col-7">
            <div class="p-inputgroup flex align-items-center">
              <span class="mr-1">
                <strong class="mt-2">Quistes: </strong>
              </span>
              <label for="quistes">SI</label>
              <RadioButton
                inputId="rinion_quistes"
                value="Si"
                v-model="data_datos_eco_abdominal.rinion_izq_quistes"
                v-tooltip.top="'SI'"
                class="mr-1"
              />
              <label for="no">NO</label>
              <RadioButton
                inputId="rinion_quistes"
                value="No"
                v-model="data_datos_eco_abdominal.rinion_izq_quistes"
                v-tooltip.top="'NO'"
                class="mr-1"
              />
              <label for="unico">Único</label>
              <RadioButton
                inputId="rinion_quistes"
                value="Unico"
                v-model="data_datos_eco_abdominal.rinion_izq_quistes"
                v-tooltip.top="'Único'"
                class="mr-1"
              />
              <label for="multiples">Múltiples</label>
              <RadioButton
                inputId="rinion_quistes"
                value="Multiples"
                v-model="data_datos_eco_abdominal.rinion_izq_quistes"
                v-tooltip.top="'Múltiples'"
                class="mr-1"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="col-12 md:col-6 grid">
          <Divider align="left" type="dashed">
            <b>PÁNCREAS:</b>
          </Divider>
          <div class="field col-12 md:col-4">
            <div class="p-inputgroup flex align-items-center">
              <span class="mr-1">
                <strong class="mt-2">Visible: </strong>
              </span>
              <label for="si">SI</label>
              <RadioButton
                inputId="si"
                value="Si"
                v-model="data_datos_eco_abdominal.pancreas_visible"
                v-tooltip.top="'SI'"
                class="mr-1"
              />
              <label for="no">NO</label>
              <RadioButton
                inputId="no"
                value="No"
                v-model="data_datos_eco_abdominal.pancreas_visible"
                v-tooltip.top="'NO'"
                class="mr-1"
              />
            </div>
          </div>
          <div class="field col-12 md:col-4">
            <span class="p-float-label">
              <Textarea
                v-model="data_datos_eco_abdominal.pancreas_aspecto"
                v-tooltip.top="'Aspecto'"
                rows="1"
                autoResize
              />
              <label for="inputtext">Aspecto...</label>
            </span>
          </div>
          <div class="field col-12 md:col-4">
            <span class="p-float-label">
              <Textarea
                v-model="data_datos_eco_abdominal.pancreas_tamanio"
                v-tooltip.top="'Tamaño'"
                rows="1"
                autoResize
              />
              <label for="inputtext">Tamaño...</label>
            </span>
          </div>
        </div>
        <Divider layout="vertical" type="dashed" />
        <div class="col-12 md:col-6 grid">
          <Divider align="left" type="dashed">
            <b>LÍQUIDO LIBRE EN CAVIDAD ABDOMINAL:</b>
          </Divider>
          <div class="field col-12 md:col-12">
            <div class="p-inputgroup flex align-items-center">
              <label for="si">SI</label>
              <RadioButton
                inputId="si"
                value="Si"
                v-model="data_datos_eco_abdominal.liquido_cavidad_abdominal"
                v-tooltip.top="'SI'"
                class="mr-2"
              />
              <label for="no">NO</label>
              <RadioButton
                inputId="no"
                value="no"
                v-model="data_datos_eco_abdominal.liquido_cavidad_abdominal"
                v-tooltip.top="'NO'"
                class="mr-2"
              />
              <label for="poco">Poco</label>
              <RadioButton
                inputId="poco"
                value="poco"
                v-model="data_datos_eco_abdominal.liquido_cavidad_abdominal"
                v-tooltip.top="'Poco'"
                class="mr-2"
              />
              <label for="abundante">Abundante</label>
              <RadioButton
                inputId="abundante"
                value="abundante"
                v-model="data_datos_eco_abdominal.liquido_cavidad_abdominal"
                v-tooltip.top="'Abundante'"
                class="mr-2"
              />
            </div>
          </div>
        </div>
      </div>
      <Divider align="left" type="dashed">
        <b>CONCLUSI&Oacute;N:</b>
      </Divider>
      <div class="p-fluid grid">
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <Textarea
              v-model="data_datos_eco_abdominal.otros"
              v-tooltip.top="'Otros'"
              rows="1"
              autoResize
            />
            <label for="inputtext">Otros...</label>
          </span>
        </div>
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <Textarea
              v-model="data_datos_eco_abdominal.conclusion"
              v-tooltip.top="'Conclusión'"
              rows="1"
              autoResize
            />
            <label for="inputtext">Conclusión...</label>
          </span>
        </div>
      </div>
      <div class="grid mt-2 justify-content-end">
        <Button
          label="LIMPIAR"
          icon="pi pi-refresh"
          class="p-button-lg p-button-secondary"
          v-tooltip.top="'Limpiar Formulario'"
          @click="(data_datos_eco_abdominal = {}), (fecha_seleccionada = null)"
        />
        <Button
          label="IMPRIMIR"
          icon="pi pi-print"
          class="p-button-lg p-button-danger ml-2"
          v-tooltip.top="'Imprimir Informe Ecográfico Abdominal'"
          @click="printEcoAbdominal()"
          :disabled="fecha_seleccionada == null"
          :loading="printing"
        />
        <Button
          label="GUARDAR ECO ABDOMINAL"
          icon="pi pi-save"
          class="p-button-lg p-button-success ml-2"
          v-tooltip.top="'Guardar Informe Ecográfico Abdominal'"
          :disabled="fecha_seleccionada != null"
          @click="modalConfirmacion()"
          :loading="loading"
        >
        </Button>
      </div>
    </div>
    <Dialog
      v-model:visible="displayModal"
      modal
      header="Confirmación"
      :style="{ width: '450px' }"
    >
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-12">
          <div class="p-inputgroup flex align-items-center">
            <span style="font-size: 12px; font-weight: bold; color: red">
              <strong>NOTA: </strong> Una vez guardado el Informe Abdominal no
              se podrá modificar.
            </span>
          </div>
          <div class="p-inputgroup flex align-items-center">
            <i
              class="pi pi-exclamation-triangle mr-2"
              style="font-size: 3rem"
            />
            <h4><strong>¿Desea Guardar el Informe Abdominal?</strong></h4>
          </div>
        </div>
      </div>
      <div class="grid justify-content-end">
        <Button
          label="NO, CANCELAR"
          icon="pi pi-times"
          class="p-button-lg p-button-danger mr-2"
          @click="cancelarEnvio()"
        />
        <Button
          label="SI, GUARDAR"
          icon="pi pi-save"
          class="p-button-lg p-button-success"
          @click="saveEcoAbdominal()"
        />
      </div>
    </Dialog>
  </div>
</template>
<script>
import HistoriaService from "@/service/HistoriaService.js";
import { useAuth } from "@/stores";

export default {
  props: {
    datos_eco_abdominal: {
      type: Object,
      default: () => {},
    },
    eco_abdominals_history: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      data_datos_eco_abdominal: this.datos_eco_abdominal,
      loading: false,
      data_eco_abdominals_history: this.eco_abdominals_history,
      fechas: [
        {
          name: "Cargando...",
          value: "Cargando...",
        },
      ],
      fecha_seleccionada: null,
      id: null,
      displayModal: false,
      printing: false,
    };
  },
  historiaService: null,
  auth: null,

  created() {
    this.historiaService = new HistoriaService();
    this.auth = useAuth();
  },
  methods: {
    modalConfirmacion() {
      this.displayModal = true;
    },
    cancelarEnvio() {
      this.displayModal = false;
      this.$toast.add({
        severity: "info",
        summary: "Información",
        detail: "Se cancelo el envio del Informe Abdominal",
        life: 3000,
      });
    },
    printEcoAbdominal() {
      let datos = {
        cod_informe: this.id,
      };
      this.printing = true;
      this.historiaService.printEcoAbdominal(datos).then(() => {
        this.printing = false;
      });
    },
    fechaSeleccionada(fecha) {
      this.fecha_seleccionada = fecha;
      this.data_datos_eco_abdominal = this.data_eco_abdominals_history.find(
        (item) => item.fecha_reg == fecha
      );
      this.id = this.data_datos_eco_abdominal.id;
    },
    saveEcoAbdominal() {
      this.loading = true;
      let datos = {
        id_historia: this.data_datos_eco_abdominal.id || null,
        cliente_id: this.data_datos_eco_abdominal.cliente_id || null,
        nombre_cliente: this.data_datos_eco_abdominal.nombre_cliente || null,
        medico_id: this.data_datos_eco_abdominal.medico_id || null,
        nombre_medico: this.data_datos_eco_abdominal.nombre_medico || null,
        ...this.data_datos_eco_abdominal,
      };

      this.historiaService
        .saveEcoAbdominal(datos)
        .then((response) => {
          this.loading = false;
          this.$toast.add({
            severity: "success",
            summary: "Historia Guardada",
            detail: response.message,
            life: 3000,
          });
          /* if (this.auth.user.name != "ENFERMERIA") {
            this.id = response.id_creado;
            this.printEcoAbdominal();
          } */
          this.$router.push({
            name: "fichas_consultas",
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: error.message,
            life: 3000,
          });
        });
    },
  },
  watch: {
    datos_eco_abdominal(val) {
      this.data_datos_eco_abdominal = val;
    },
    eco_abdominals_history(val) {
      this.data_eco_abdominals_history = val;
      this.fechas = this.data_eco_abdominals_history.map((item) => {
        return {
          name: item.fecha_reg,
          value: item.fecha_reg,
        };
      });

      this.fechas.forEach((item) => {
        item.name = item.name.split(" ")[0];
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.p-invalid {
  color: red;
}
.p-float-label {
  .p-inputtextarea {
    color: #0062ff;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px dotted #00346b;
    &:focus {
      border-bottom: px solid #00346b;
    }
  }
}
.p-float-label label {
  color: #003a6e;
}
.p-divider {
  font-weight: bold;
  color: #003a6e;
}
//las letras delo radio button negrita y azul
.p-radiobutton-label {
  font-weight: bold;
  color: #003a6e;
}
.color-linea {
  border: 2px solid #003a6e;
  border-radius: 5px;
}
</style>